import { Component, OnDestroy, OnInit } from "@angular/core";
import { SendRequestService } from "./services/send-request.service";
import { environment } from "../environments/environment";
import { Event, NavigationEnd, NavigationStart, Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";

import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Subscription } from "rxjs";



@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],


})
export class AppComponent implements OnInit, OnDestroy {
  title = "Avalanchio";
  apiUrl: any = environment.apiURL;
  opened: boolean = false;
  loading: boolean = false;


  navbarAuth = ['/invoice-page/', '/invoice', '/complete-payment', '/grant-token'];
  sidebarAuth = ['/home', '/login', '/signup', '/reset-password', '/change-password', '/verify-email'
    , '/invoice-page/', '/product-details', '/product-search', '/contact-us', '/landing', '/invoice', '/complete-payment', '/oauth2-login-code/', '/oauth2-login-code', '/email-unsubscribe', '/logout', '/authorize', '/grant-token'];
  subscription: Subscription;
  drawerVisible: boolean = true;
  loggedIn: boolean;
  poller: NodeJS.Timeout;

  constructor(
    private apiService: SendRequestService,
    private route: ActivatedRoute,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      "collapse-btn",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/svgs/collapse.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "expand-btn",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/svgs/expand.svg")
    );
  }


  hasRoute() {
    return !this.navbarAuth.some(navbarAuth => this.router.url.startsWith(navbarAuth));

  }




  bodyClicked(event) {
    this.apiService.drawerOpen = false;
  }



  ngOnInit(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.drawerVisible = !this.sidebarAuth.some(sidebarPath => this.router.url.startsWith(sidebarPath));
        // this.drawerVisible = !this.sidebarAuth.some(sidebarPath => event.url.includes(sidebarPath));



      }
    });
  }

  setDrawerVisibility() {

    this.drawerVisible = !this.sidebarAuth.some(sidebarPath => this.router.url.startsWith(sidebarPath));

    if (!this.drawerVisible) {
      return;
    }

    this.subscription = this.apiService.getSelectedAppControls().subscribe((controls) => {
      if (this.apiService.isEmpty(controls)) {
        this.drawerVisible = false;
      } else {
        this.drawerVisible = true;

      }
    });
    //   this.apiService.isDrawerVisible = value;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
